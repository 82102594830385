import axios from 'axios';
// import router from '../routerManuaConfig'
import router from '../router/index'
import store from "../store";
import Vue from 'vue';

let base = '';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
//let base = process.env.NODE_ENV=="production"? 'http://api.training.wanghuolou.cn:82':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:5001':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:1004':'';

// 请求延时
axios.defaults.timeout = 60000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
        // if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
        if (storeTemp.state.token) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + storeTemp.state.token;
        }
        saveRefreshtime();
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.status == 401) {
            var curTime = new Date()
            var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
            // 在用户操作的活跃期内
            if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                return refreshToken({ token: window.localStorage.Token }).then((res) => {
                    if (res.success) {
                        store.commit("saveToken", res.response.token);
                        var curTime = new Date();
                        var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                        store.commit("saveTokenExpire", expiredate);
                        error.config.__isRetryRequest = true;
                        error.config.headers.Authorization = 'Bearer ' + res.response.token;
                        return axios(error.config);
                    } else {
                        ToLogin()
                        return false;
                    }
                })
                .catch((err) => {
                    ToLogin()
                    return false;
                })
            } else {
                ToLogin()
                return false;
            }
        } else if (response.data.status == 403) {
            Vue.prototype.$message({
                message: '失败！该操作无权限',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 429) {
            Vue.prototype.$message({
                message: '刷新次数过多，请稍事休息重试！',
                type: 'error'
            });
            return null;
        } else if (response.data.status == 500) {
            setTimeout(function () {
                Vue.prototype.$message({
                    message: '服务器错误，请稍后重试！',
                    type: 'error'
                });
            }, 1000)
            return null;
        }
        return response;
    },
    error => {
        // 超时请求处理
        var originalRequest = error.config;
        console.log(error.config)
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
            Vue.prototype.$message({
                message: '请求超时！',
                type: 'error'
            });
            originalRequest._retry = true
            return null;
        }
        if (error.response) {
            if (error.response.status == 401) {
                var curTime = new Date()
                var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                // 在用户操作的活跃期内
                if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                    return refreshToken({ token: window.localStorage.Token }).then((res) => {
                        if (res.success) {
                            // Vue.prototype.$message({
                            //     message: 'refreshToken success! loading data...',
                            //     type: 'success'
                            // });
                            store.commit("saveToken", res.response.token);
                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expiresIn));
                            store.commit("saveTokenExpire", expiredate);
                            error.config.__isRetryRequest = true;
                            error.config.headers.Authorization = 'Bearer ' + res.response.token;
                            return axios(error.config);
                        } else {
                            // 刷新token失败 清除token信息并跳转到登录页面
                            ToLogin()
                            return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                        }
                    });
                } else {
                    // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                    return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                }
            }
            // 403 无权限
            if (error.response.status == 403) {
                Vue.prototype.$message({
                    message: '失败！该操作无权限',
                    type: 'error'
                });
                return null;
            }
            // 429 ip限流
            if (error.response.status == 429) {
                Vue.prototype.$message({
                    message: '刷新次数过多，请稍事休息重试！',
                    type: 'error'
                });
                return null;
            }
            // 500
            if (error.response.status == 500) {
                setTimeout(function () {
                    Vue.prototype.$message({
                        message: '服务器错误，请稍后重试！',
                        type: 'error'
                    });
                }, 1000)
                return null;
            }
        }
        return ""; // 返回接口返回的错误信息
    }
);
const ToLogin = params => {
    store.commit("saveToken", "");
    store.commit("saveTokenExpire", "");
    store.commit("saveTagsData", "");
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('NavigationBar');
    var routerGo = router.currentRoute.fullPath.substring(0, 6)
    if (routerGo == "/login") {
    } else {
        router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
        });
    }
    // router.replace({
    //     path: "/login",
    //     query: {redirect: router.currentRoute.fullPath}
    // });
    // 调试登录过期后重新登录第一次无法跳转到相应的页面，而是停留在登录页面，所以暂时注释，怀疑重新加载所以看不到console.log("redirect_api--") date:2020-12-23
    window.location.reload()
    return false;  //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
};
export const BaseApiUrl = base;
export const saveRefreshtime = params => {
    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))
    let refreshCount = 1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime = nowtime > expiretime ? nowtime : expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    } else {
        window.localStorage.refreshtime = new Date(-1);
    }
};

// 系统登录
export const requestLogin = params => {
    return axios.post(`${base}/aidrwebapi/login/login`, params).then(res => res.data);
};

//AI系统登录
export const aiDoLogin = params => {
    return axios.get(`${base}/aidrwebapi/login/aiDoLogin`, { params: params });
};

// 获取登录短信验证码
export const getLoginCode = params => {
    return axios.get(`${base}/aidrwebapi/login/getLoginCode`, { params: params }).then(res => res.data);
};

// 短信验证码登录
export const codelogin = params => {
    return axios.get(`${base}/aidrwebapi/login/codelogin`, { params: params }).then(res => res.data);
};

//刷新token
export const refreshToken = params => {
    return axios.get(`${base}/aidrwebapi/login/refreshToken`, { params: params }).then(res => res.data);
};

// ==============================首页接口STAR======================================================================
//首页
//获取报名人数的图表数据
export const examenrollCountGroupByDate = params => {
    return axios.get(`${base}/aidrwebapi/Home/ExamenrollCountGroupByDate`, { params: params });
};
//根据行政区获取报名人数的图表数据
export const examenrollCountGroupByDateByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/ExamenrollCountGroupByDateByID`, { params: params });
};

//获取考试项目报名人数统计
export const examenrollCountGroupByProjectName = params => {
    return axios.get(`${base}/aidrwebapi/Home/ExamenrollCountGroupByProjectName`, { params: params });
};
//根据行政区获取考试项目报名人数统计
export const examenrollCountGroupByProjectNameByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/ExamenrollCountGroupByProjectNameByID`, { params: params });
};

// 社会单位总数及学习中数量
export const getUnitsAndCertificatesResponse = params => {
    return axios.get(`${base}/aidrwebapi/Home/getUnitsAndCertificatesResponse`, { params: params });
};

// 下级社会单位总数及学习中数量
export const getUnitsAndCertificatesResponseByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/getUnitsAndCertificatesResponseByID`, { params: params });
};

// 下级城市/地区的社会单位、持证总数、证书达标数列表
export const getProportionCertificateHolders = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetProportionCertificateHolders`, { params: params });
};

// 下级城市/地区的持证总数统计
export const getProportionCertificateHoldersByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetProportionCertificateHoldersByID`, { params: params });
};

// 社会单位持证统计分析
export const getSocialUnitCertificateHoldersStatistics = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetSocialUnitCertificateHoldersStatistics`, { params: params });
};

// 待监管单位
export const getHomeSocialUnitStatistics = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetHomeSocialUnitStatistics`, { params: params });
};

// 地图滑上社会单位，学习中，取证，待监管数量
export const getUnitsAndCertificatesSingleResponseByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/getUnitsAndCertificatesSingleResponseByID`, { params: params });
};

//首页改版新接口
// 共用-获取地图上方滑上浮窗数据
export const getStudentLearnRecords = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetStudentLearnRecords`, { params: params });
};
// 共用-获取地图地标接口
export const getJsonFile = params => {
    return axios.get(`${base}/aidrwebapi/GeoJson/GetJsonFile`, { params: params });
};

// 概况
// 消防安全知识知晓率排名
export const getMaterialExamPaperRightRatio = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetMaterialExamPaperRightRatio`, { params: params });
};
// 获取待监管单位数/单位总数接口
export const getToBeSupervisedSocialUnitCount = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetToBeSupervisedSocialUnitCount`, { params: params });
};
// 本级概况
export const getTrainingExaminationCertificateStatistics = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetTrainingExaminationCertificateStatistics`, { params: params });
};
// 获取地图浮窗数据
export const getUnitAndSupervisionResponseStatistics = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetUnitAndSupervisionResponseStatistics`, { params: params });
};
// 下辖概况
export const getTrainingExaminationCertificateByID = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetTrainingExaminationCertificateByID`, { params: params });
};
// 饼状图概况
export const getAgencyInfoPass = params => {
    return axios.get(`${base}/aidrwebapi/Home/getAgencyInfoPass`, { params: params });
};

// 报考情况
// 获取每日报考人数
export const getExamenrollCountGroupByDateByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/ExamenrollCountGroupByDateByID`, { params: params });
};
// 根据岗位/行业获取报考人数/应训人数
export const getTrainingCountGroupByTradeByIDByType = params => {
    return axios.get(`${base}/aidrwebapi/Home/TrainingCountGroupByTradeByIDByType`, { params: params });
};
// 本级报考情况
export const getUnitAndRegistrationCountRateStatisticsByID = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetUnitAndRegistrationCountRateStatisticsByID`, { params: params });
};
// 获取地图浮窗数据
export const getMapRegistrationRateResponseStatistics = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetMapRegistrationRateResponseStatistics`, { params: params });
};
// 下辖报考情况
export const getUnitAndRegistrationCountRateStatistics = params => {
    return axios.get(`${base}/aidrwebapi/GeneralizationStatistics/GetUnitAndRegistrationCountRateStatistics`, { params: params });
};

// 参训情况
// 获取每日参训人数
export const getAttendTrainingCountGroupByDate = params => {
    return axios.get(`${base}/aidrwebapi/Home/AttendTrainingCountGroupByDate`, { params: params });
};
// 根据岗位/行业获取参训人数/应训人数
export const getTrainingCountGroupByTradeByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/TrainingCountGroupByTradeByID`, { params: params });
};
// 本级参训情况
export const getCurrentLevelParticipationByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetCurrentLevelParticipationByID`, { params: params });
};
// 获取地图浮窗数据
export const getTrainingMap = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetTrainingMap`, { params: params });
};
// 下辖参训情况
export const getParticipatingJurisdictions = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetParticipatingJurisdictions`, { params: params });
};
// 参训人数概况
export const getTrainingCountByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetTrainingCountByID`, { params: params });
};
// 获取乡镇下辖单位概况明细
export const getParticipatingJurisdictionsRegion = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetParticipatingJurisdictionsRegion`, { params: params });
};

// 持证情况
// 获取每日取证人数
export const getCertificateCountGroupByDate = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetCertificateCountGroupByDate`, { params: params });
};
// 根据岗位/行业获取持证人数/应训人数
export const getCertificateCountGroupByTradeByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/CertificateCountGroupByTradeByID`, { params: params });
};
// 本级持证情况
export const getCurrentLevelCertificateHoldingByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetCurrentLevelCertificateHoldingByID`, { params: params });
};
// 获取地图浮窗数据
export const getMapOfCertificateHolding = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetMapOfCertificateHolding`, { params: params });
};
// 下辖持证情况
export const getCertificateHoldingUnderTheJurisdiction = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetCertificateHoldingUnderTheJurisdiction`, { params: params });
};
// 持证人数概况
export const getCertificateCountByID = params => {
    return axios.get(`${base}/aidrwebapi/Home/GetCertificateCountByID`, { params: params });
};
// ===============================首页接口END===========================================================================

//系统管理
//用户管理
export const getUserListPage = params => {
    return axios.get(`${base}/aidrwebapi/userInfo/getUserInfoListPage`, { params: params });
};

// 根据token获取用户详细信息
export const getUserByToken = params => {
    return axios.get(`${base}/aidrwebapi/userInfo/getUserInfoByToken`, { params: params }).then(res => res.data);
};

//新增用户信息
export const addUserInfo = params => {
    return axios.post(`${base}/aidrwebapi/userInfo/addUserInfo`, params);
};

//更新用户信息
export const updateUserInfo = params => {
    return axios.post(`${base}/aidrwebapi/userInfo/updateUserInfo`, params);
};

//删除用户信息
export const deleteUserInfo = params => {
    return axios.delete(`${base}/aidrwebapi/userInfo/deleteUserInfo`, { params });
};

//发送修改密码手机短信验证码
export const sendModifyPasswordSMSVerificationCode = params => {
    return axios.post(`${base}/aidrwebapi/SystemManager/SendModifyPasswordSMSVerificationCode`, params);
};

//修改用户登录密码
export const modifyUserPassword = params => {
    return axios.post(`${base}/aidrwebapi/userInfo/modifyUserPassword`, params);
};

//系统管理
//菜单权限管理

//根据用户ID获取路由树
export const getNavigationBar = params => {
    return axios.get(`${base}/aidrwebapi/menuPermissions/getNavigationBar`, { params: params }).then(res => res.data);
};

//获取菜单树
export const getMenuTree = params => {
    return axios.get(`${base}/aidrwebapi/menuPermissions/getMenuTree`, { params: params });
};

//获取菜单权限树形列表
export const getMenuPermissionsTree = params => {
    return axios.get(`${base}/aidrwebapi/menuPermissions/getMenuPermissionsTree`, { params: params });
};

//新增菜单权限信息
export const addMenuPermissionsInfo = params => {
    return axios.post(`${base}/aidrwebapi/menuPermissions/addMenuPermissionsInfo`, params);
};

//更新菜单权限信息
export const updateMenuPermissionsInfo = params => {
    return axios.post(`${base}/aidrwebapi/menuPermissions/updateMenuPermissionsInfo`, params);
};

//删除菜单权限信息
export const deleteMenuPermissionsInfo = params => {
    return axios.delete(`${base}/aidrwebapi/menuPermissions/deleteMenuPermissionsInfo`, { params: params });
};

//保存菜单权限分配
export const assignMenuPermissions = params => {
    return axios.post(`${base}/aidrwebapi/menuPermissions/assignMenuPermissions`, params);
};

//通过角色获取菜单【无权限】
export const GetAssignMenuPermissionIdByRoleId = params => {
    return axios.get(`${base}/aidrwebapi/menuPermissions/getAssignMenuPermissionIdByRoleId`, { params: params });
};

//系统管理
//角色管理

//根据组织结构ID获取角色信息集合
export const getRoleInfosByOrganizationInfoID = params => {
    return axios.get(`${base}/aidrwebapi/roleInfo/getRoleInfoByName`, { params: params });
};

//获取角色信息分页列表
export const getRoleListPage = params => {
    return axios.get(`${base}/aidrwebapi/roleInfo/getRoleInfoListPage`, { params: params });
};

//新增角色信息
export const addRoleInfo = params => {
    return axios.post(`${base}/aidrwebapi/roleInfo/addRoleInfo`, params);
};

//更新角色信息
export const updateRoleInfo = params => {
    return axios.post(`${base}/aidrwebapi/roleInfo/updateRoleInfo`, params);
};

//删除角色信息
export const deleteRoleInfo = params => {
    return axios.delete(`${base}/aidrwebapi/roleInfo/deleteRoleInfo`, { params: params });
};

//系统管理
// 部门管理

//获取组织结构树
export const getOrganizationTree = params => {
    return axios.get(`${base}/aidrwebapi/departmentInfo/getDepartmentTree`, { params: params });
};

//新增组织结构信息
export const addOrganizationInfo = params => {
    return axios.post(`${base}/aidrwebapi/departmentInfo/addDepartmentInfo`, params);
};

//更新组织结构信息
export const updateOrganizationInfo = params => {
    return axios.post(`${base}/aidrwebapi/departmentInfo/updateDepartmentInfo`, params);
};

//删除组织结构信息
export const deleteOrganizationInfo = params => {
    return axios.delete(`${base}/aidrwebapi/departmentInfo/deleteDepartmentInfo`, { params: params });
};

//系统管理
// 接口管理

//获取接口信息树形列表
export const getApiModulesInfoTree = params => {
    return axios.get(`${base}/aidrwebapi/apiModulesInfo/getApiModulesInfoTree`, { params: params });
};

//获取接口菜单树
export const getApiMenuTree = params => {
    return axios.get(`${base}/aidrwebapi/apiModulesInfo/getApiMenuTree`, { params: params });
};

//新增接口信息
export const addApiModulesInfo = params => {
    return axios.post(`${base}/aidrwebapi/apiModulesInfo/addApiModulesInfo`, params);
};

//更新接口信息
export const updateApiModulesInfo = params => {
    return axios.post(`${base}/aidrwebapi/apiModulesInfo/updateApiModulesInfo`, params);
};

//删除接口信息
export const deleteApiModulesInfo = params => {
    return axios.delete(`${base}/aidrwebapi/apiModulesInfo/deleteApiModulesInfo`, { params: params });
};

//系统管理
// 数据权限管理

//获取全部数据权限信息列表
export const getAllDataPermissionsInfoList = params => {
    return axios.get(`${base}/aidrwebapi/dataPermissionsInfo/getAllDataPermissionsInfoList`, { params: params });
};

//新增数据权限信息
export const addDataPermissionsInfo = params => {
    return axios.post(`${base}/aidrwebapi/dataPermissionsInfo/addDataPermissionsInfo`, params);
};

//更新数据权限信息
export const updateDataPermissionsInfo = params => {
    return axios.post(`${base}/aidrwebapi/dataPermissionsInfo/updateDataPermissionsInfo`, params);
};

//删除数据权限信息
export const deleteDataPermissionsInfo = params => {
    return axios.delete(`${base}/aidrwebapi/dataPermissionsInfo/deleteDataPermissionsInfo`, { params });
};

//保存数据权限分配
export const assignDataPermissions = params => {
    return axios.post(`${base}/aidrwebapi/dataPermissionsInfo/assignDataPermissions`, params);
};

//通过角色ID获取数据权限菜单【无权限】
export const getAssignDataPermissionIdByRoleId = params => {
    return axios.get(`${base}/aidrwebapi/dataPermissionsInfo/getAssignDataPermissionIdByRoleId`, { params: params });
};

//通用设置
//审批流程管理

//获取审批流程信息分页列表
export const getApprovalProcessListPage = params => {
    return axios.get(`${base}/aidrwebapi/CommonSettings/GetApprovalProcessListPage`, { params: params });
};

//新增审批流程
export const addApprovalProcess = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/AddApprovalProcess`, params);
};

//更新审批流程
export const updateApprovalProcess = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/UpdateApprovalProcess`, params);
};

//根据审批流程ID 获取审批流程图
export const getApprovalProcessDiagramByApprovalProcessID = params => {
    return axios.get(`${base}/aidrwebapi/CommonSettings/GetApprovalProcessDiagramByApprovalProcessID`, { params: params });
};

//通用设置
//展示信息

//获取展示信息分页集合
export const getShowInformationListPage = params => {
    return axios.get(`${base}/aidrwebapi/CommonSettings/GetShowInformationListPage`, { params: params });
};

//新增展示信息
export const addShowInformation = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/AddShowInformation`, params);
};

//更新展示信息
export const updateShowInformation = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/UpdateShowInformation`, params);
};

//删除展示信息
export const deleteShowInformation = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/DeleteShowInformation`, params);
};

//顶置/取消顶置展示信息
export const setTopShowInformation = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/SetTopShowInformation`, params);
};

//通用设置
//轮播信息

//获取轮播信息分页集合
export const getCarouselInformationList = params => {
    return axios.get(`${base}/aidrwebapi/CommonSettings/GetCarouselInformationList`, { params: params });
};

//新增轮播信息
export const addCarouselInformation = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/AddCarouselInformation`, params);
};

//更新轮播信息
export const updateCarouselInformation = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/UpdateCarouselInformation`, params);
};

//删除轮播信息
export const deleteCarouselInformation = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/DeleteCarouselInformation`, params);
};

//通用设置
//行政区域

//获取行政区信息列表
// export const getAreaDictionaryList = params => {
//     return axios.get(`${base}/aidrwebapi/CommonSettings/GetAreaDictionaryList`, { params: params });
// };

// 省市区(code)
export const getAreaDictionaryByCode = params => {
    return axios.get(`${base}/aidrwebapi/areaDictionary/getAreaDictionaryByCode`, { params: params });
};
// 省市区(id)
export const getAreaDictionaryById = params => {
    return axios.get(`${base}/aidrwebapi/areaDictionary/getAreaDictionaryById`, { params: params });
};

// 省市区
export const getAreaDictionaryList = params => {
    return axios.get(`${base}/aidrwebapi/areaDictionary/getAreaDictionaryList`, { params: params });
};
// 省市区
export const getAreaDictionaryListWithCode = params => {
    return axios.get(`${base}/aidrwebapi/areaDictionary/getAreaDictionaryListWithCode`, { params: params });
};

//新增行政区信息
export const addAreaDictionary = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/AddAreaDictionary`, params);
};

//更新行政区信息
export const updateAreaDictionary = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/UpdateAreaDictionary`, params);
};

//删除行政区信息
export const deleteAreaDictionary = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/DeleteAreaDictionary`, params);
};

//抓取行政区信息
export const grabAreaDictionary = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/GrabAreaDictionary`, params);
};

//通用设置
//数据字典信息

//获取数据字典信息列表
export const getDataDictionaryList = params => {
    return axios.get(`${base}/aidrwebapi/dataDictionary/getById`, { params: params });
};

//新增数据字典信息
export const addDataDictionary = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/AddDataDictionary`, params);
};

//更新数据字典信息
export const updateDataDictionary = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/UpdateDataDictionary`, params);
};

//删除数据字典信息
export const deleteDataDictionary = params => {
    return axios.post(`${base}/aidrwebapi/CommonSettings/DeleteDataDictionary`, params);
};
// 根据命名空间获取数据字典
export const getByNamespace = params => {
    return axios.get(`${base}/aidrwebapi/dataDictionary/getByNamespace`, { params: params });
};


//工作流管理
//审批
export const approve = params => {
    return axios.post(`${base}/aidrwebapi/WorkFlow/Approve`, params);
};

//获取待处理信息分页列表
export const getPendingIssuesListPage = params => {
    return axios.get(`${base}/aidrwebapi/WorkFlow/GetPendingIssuesListPage`, { params: params });
};

//获取已处理信息分页列表
export const getProcessedIssuesListPage = params => {
    return axios.get(`${base}/aidrwebapi/WorkFlow/GetProcessedIssuesListPage`, { params: params });
};

//获取已发起信息分页列表
export const getLaunchIssuesListPage = params => {
    return axios.get(`${base}/aidrwebapi/WorkFlow/GetLaunchIssuesListPage`, { params: params });
};

//获取全部事项分页列表
export const getAllIssuesListPage = params => {
    return axios.get(`${base}/aidrwebapi/WorkFlow/GetAllIssuesListPage`, { params: params });
};

//根据审批记录ID获取试题审批记录详情
export const getExaminationQuestionsApprovalRecordByID = params => {
    return axios.get(`${base}/aidrwebapi/WorkFlow/GetExaminationQuestionsApprovalRecordByID`, { params: params });
};

//根据审批记录ID获取发布考试项目详情
export const getPublishExamProjectInfoByID = params => {
    return axios.get(`${base}/aidrwebapi/WorkFlow/GetPublishExamProjectInfoByID`, { params: params });
};

//图片管理
//上传图片
export const uploadTempPic = params => {
    return axios.post(`${base}/aidrwebapi/Image/UploadTempPic`, params);
};

// 上传图片路径
export const uploadImagesUrl = `${base}/aidrwebapi/fileTransfer/uploadPic`

// 项目管理
// 项目登记

//退回
export const returnUploadKYJCProjectStatus = params => {
    return axios.get(`${base}/aidrwebapi/drProject/returnUploadKYJCProjectStatus`, {params:params});
};

//完成项目登记（开业检查）
export const isCompletedKYJCProject = params => {
    return axios.get(`${base}/aidrwebapi/drProject/isCompletedKYJCProject`, {params:params});
};

//获取工作日时长
export const getProjectListTime = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getWorkDateNum?from=`+ params.from+'&'+'to='+ params.to);
};

//获取申报表
export const getKYJCApplicationByProjectId = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getKYJCApplicationByProjectId`, { params: params });
};

// 获取项目分页列表
export const getProjectList = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectList`, { params: params });
};

// 新增项目
export const addProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/addProject`, params);
};
export const convertFormalProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/convertFormalProject`, params);
};

//上传申报表
export const addProjectFile = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/addProjectFile`, params);
};
//更新开业检查项目申报表
export const updateProjectFile = params => {
    return axios.post(`${base}/aidrwebapi/kyjc/updateProject`, params);
};
//获取用户信息
export const getAccountInfo = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getAccountInfo`, { params: params });
};
//获取开业检查项目申报表详情
export const getApplicationByProjectId = params => {
    return axios.get(`${base}/aidrwebapi/kyjc/getApplicationByProjectId`, { params: params });
};

//获取开业检查流程列表
export const getKYJCProjectStageList = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getKYJCProjectStageList`, { params: params });
};



// 更新项目
export const updateProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/updateProject`, params);
};

// 更新部分项目信息
export const updateProjectInfo = params => {
    return axios.post(`${base}/aidrwebapi/drProject/updateProjectInfo`, params);
};

// 重新审图
export const resetDrawingReviewApi = params => {
    return axios.post(`${base}/aidrwebapi/drProject/resetDrawingReview`, params);
};

// 更新审查报告编号
export const updateCustomReportNo = params => {
    return axios.post(`${base}/aidrwebapi/drProject/updateCustomReportNo`, params);
};

// 新增项目申请表
export const addProjectApplication = params => {
    return axios.post(`${base}/aidrwebapi/drProject/addProjectApplication`, params);
}

// 更新项目申请表
export const updateProjectApplication = params => {
    return axios.post(`${base}/aidrwebapi/drProject/updateProjectApplication`, params);
}

// 完成登记项目接口
export const registerProject = params => {
    return axios.get(`${base}/aidrwebapi/drProject/registerProject`, { params: params });
};

// 获取项目文件目录
export const getProjectFileInfo = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectFileInfo`, { params: params });
};

export const getProjectFileInfoByFlowNodeId = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectFileInfoByFlowNodeId`, { params: params });
};

// 获取项目详情
export const getProjectById = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectById`, { params: params });
};

//生成临时Url链接
export const generateTemporaryUrlByFileName = params => {
    return axios.get(`${base}/aidrwebapi/fileTransfer/generateTemporaryUrlByFileName`, { params: params });
};

//获取项目压缩文件
export const getCompressedProjectFile = params => {
    return axios.get(`${base}/aidrwebapi/fileTransfer/getCompressedProjectFile`, { params: params });
};

export const generateTemporaryUrl = params => {
    return axios.get(`${base}/aidrwebapi/fileTransfer/generateTemporaryUrl`, { params: params });
};

// 根据项目ID获取阶段列表
export const getStageListByProjectId = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getStageListByProjectId`, { params: params });
};


// 获取合同详情
export const getDrContractById = params => {
    return axios.get(`${base}/aidrwebapi/drContract/getDrContractById`, { params: params });
};

// 根据项目Id获取项目申请表
export const getProjectApplicationByProjectId = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectApplicationByProjectId`, { params: params });
};

// 上传识别文件
export const uploadApplicationPic = params => {
    return axios.post(`${base}/aidrwebapi/drProject/uploadApplicationPic`, params);
}
// 上传识别文件
export const uploadApplicationPicUrl = `${base}/aidrwebapi/drProject/ocrApplicationPic`

//验收管理 文件上传
export const uploadFile = `${base}/aidrwebapi/fileTransfer/uploadFile`

// 在审项目管理
// 在审项目

// 获取在审项目分页列表
export const getCheckingProjectList = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getCheckingProjectList`, {params: params});
};

//快速生成报告
export const generationPreviousDrawingReviewReport = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/generationPreviousDrawingReviewReport`, {params: params});
};

// 审结项目
export const conclusionProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/conclusionProject`, params);
};

// 申请援助
export const applyAssistance = params => {
    return axios.post(`${base}/aidrwebapi/drTask/applyAssistance`, params);
};

// 重新发起签章
export const signatureProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/resetProjectSign`, params);
};

//获取留言
export const getProjectMessageByProjectId = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectMessageByProjectId`, { params: params });
};

// 新增留言
export const addProjectMessage = params => {
    return axios.post(`${base}/aidrwebapi/drProject/addProjectMessage`, params);
};

//获取项目参与人员
export const getProjectParticipants = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectParticipants`, { params: params });
};

//更改到期时间

export const changeTaskOverdueDate = params => {
    return axios.post(`${base}/aidrwebapi/drTask/changeTaskOverdueDate`,  params );
};

//复核报告
export const getProjectReports = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectDoubleReports`, { params: params });
};

//图审人员初审意见
export const getPreliminaryTrialOpinionListByPrProjectId = params => {
    return axios.get(`${base}/aidrwebapi/drOpinion/getPreliminaryTrialOpinionListByPrProjectId`, { params: params });
};

//查看审图意见
export const getOpinionListByWorkFlowId = params => {
    return axios.get(`${base}/aidrwebapi/drOpinion/getOpinionListByWorkFlowId`, { params: params });
};


//图审人员初审报告（弃用）
export const getPreliminaryReviewReportByPrProjectId = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/getPreliminaryReviewReportByPrProjectId`, { params: params });
};

//图审人员初审报告
export const getProjectFirstReports = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectFirstReports`, { params: params });
};

//图审报告
export const getProjectConclusionReports = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectConclusionReports`, { params: params });
};

//图审人员复核报告
export const getProjectDoubleReports = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectDoubleReports`, { params: params });
};

//图审人员复核列表
export const getTaskReviewList = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getTaskReviewList`, { params: params });
};

//图审人员此次复核意见列表
export const getReviewOpinionList = params => {
    return axios.get(`${base}/aidrwebapi/drOpinion/getReviewOpinionList`, { params: params });
};

//项目审结期限倒计时天数
export const getProjectConclusionExpire = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectConclusionExpire`, { params: params });
};

// 不合格项目归档

// 不合格项目列表
export const getDrProjectUnqualifiedList = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getDrProjectUnqualifiedList`, { params: params });
};

// 项目异常处理
export const handleUnqualifiedProject = params => {
    return axios.get(`${base}/aidrwebapi/drProject/handleUnqualifiedProject`, { params: params });
};

// 档案查询

// 获取档案列表
export const getDrProjectFiledtList = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getDrProjectFiledtList`, { params: params });
};

//统计汇总
//获取意见明细列表
export const getStatisticsKYJCOpininDetail = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getStatisticsKYJCOpininDetail`, { params: params });
};

// 获取意见汇总
export const getStatisticsKYJCOpininList = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getStatisticsKYJCOpininList`, { params: params });
};

//审查情况分析
export const getStatisticsKYJCAiStandardData = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getStatisticsKYJCAiStandardData`, { params: params });
};

//审查情况分析复审
export const getStatisticsKYJCReviewAiStandardData = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getStatisticsKYJCReviewAiStandardData`, { params: params });
};

// 规范
//获取规范列表
export const getDrStandardList = params => {
    return axios.get(`${base}/aidrwebapi/drStandard/getDrStandardList`, { params: params });
};

// 获取单个规范内容
export const getDrStandardById = params => {
    return axios.get(`${base}/aidrwebapi/drStandard/getDrStandardById`, { params: params });
};

// 规范新增
export const addDrStandard = params => {
    return axios.post(`${base}/aidrwebapi/drStandard/addDrStandard`, params);
};

// 规范编辑
export const updateDrStandard = params => {
    return axios.post(`${base}/aidrwebapi/drStandard/updateDrStandard`, params);
};

// 规范删除
export const deleteDrStandard = params => {
    return axios.delete(`${base}/aidrwebapi/drStandard/deleteDrStandard`, { params: params });
};

// 所有规范 无分页
export const getAllDrStandardList = params => {
    return axios.get(`${base}/aidrwebapi/drStandard/getAllDrStandardList`, { params: params });
};

// 规范名下所有条文
export const getAllDrStandardItemList = params => {
    return axios.get(`${base}/aidrwebapi/drStandard/getAllDrStandardItemList`, { params: params });
};

// 条文
//获取条文列表
export const getDrStandardItemList = params => {
    return axios.get(`${base}/aidrwebapi/drStandardItem/getDrStandardItemList`, { params: params });
};

// 获取条文信息
export const getDrStandardItemById = params => {
    return axios.get(`${base}/aidrwebapi/drStandardItem/getDrStandardItemById`, { params: params });
};

// 查询条文
export const getDrStandardItem = params => {
    return axios.get(`${base}/aidrwebapi/drStandardItem/getDrStandardItem`, { params: params });
};

// 条文新增
export const addDrStandardItem = params => {
    return axios.post(`${base}/aidrwebapi/drStandardItem/addDrStandardItem`, params);
};

// 新增条文规则 (款 项 目)
export const addDrStandardItemRule = params => {
    return axios.post(`${base}/aidrwebapi/drStandardItem/addDrStandardItemRule`, params);
};


// 条文编辑
export const updateDrStandardItem = params => {
    return axios.post(`${base}/aidrwebapi/drStandardItem/updateDrStandardItem`, params);
};

// 条文删除
export const deleteDrStandardItem = params => {
    return axios.delete(`${base}/aidrwebapi/drStandardItem/deleteDrStandardItem`, { params: params });
};

// 查看条文注释
export const getDrStandardItemNotesList = params => {
    return axios.get(`${base}/aidrwebapi/drStandardItem/getDrStandardItemNotesList`, { params: params });
};

// 查看条文所有注释
export const getAllStandardItemNotesByItemRootId = params => {
    return axios.get(`${base}/aidrwebapi/drStandardItem/getAllStandardItemNotesByItemRootId`, { params: params });
};

// 保存注
export const saveDrStandardItemNotes = params => {
    return axios.post(`${base}/aidrwebapi/drStandardItem/saveDrStandardItemNotes`, params);
};

// 注删除
export const deleteDrStandardItemNotes = params => {
    return axios.delete(`${base}/aidrwebapi/drStandardItem/deleteDrStandardItemNotes`, { params: params });
};

// 审查内容
//获取审查内容分页列表
export const getAuditContentListPage = params => {
    return axios.get(`${base}/aidrwebapi/drAuditContent/getAuditContentListPage`, { params: params });
};

// 获取所有审查对象
export const getAuditContentAllList = params => {
    return axios.get(`${base}/aidrwebapi/drAuditContent/getAuditContentAllList`, { params: params });
};

// 获取审查对象树状图
export const getAuditContentTreeList = params => {
    return axios.get(`${base}/aidrwebapi/drAuditContent/getAuditContentTreeList`, { params: params });
};

// 查询审查内容
export const getAuditContentById = params => {
    return axios.get(`${base}/aidrwebapi/drAuditContent/getAuditContentById`, { params: params });
};

// 审查内容新增
export const addAuditContent = params => {
    return axios.post(`${base}/aidrwebapi/drAuditContent/addAuditContent`, params);
};

// 审查内容编辑
export const updateAuditContent = params => {
    return axios.post(`${base}/aidrwebapi/drAuditContent/updateAuditContent`, params);
};

// 审查内容删除
export const deleteAuditContent = params => {
    return axios.delete(`${base}/aidrwebapi/drAuditContent/deleteAuditContent`, { params: params });
};

// 问题库
//获取问题库分页列表
export const getQuestionPageList = params => {
    return axios.get(`${base}/aidrwebapi/drQuestion/getQuestionPageList`, { params: params });
};

// 根据问题编号或内容获取问题库列表
export const getQuestionById = params => {
    return axios.get(`${base}/aidrwebapi/drQuestion/getQuestionById`, { params: params });
};

// 问题库新增
export const addQuestion = params => {
    return axios.post(`${base}/aidrwebapi/drQuestion/addQuestion`, params);
};

// 问题库编辑
export const updateQuestion = params => {
    return axios.post(`${base}/aidrwebapi/drQuestion/updateQuestion`, params);
};

// 问题库删除
export const deleteQuestion = params => {
    return axios.delete(`${base}/aidrwebapi/drQuestion/deleteQuestion`, { params: params });
};

// 图审人员
// 查询
export const getExpertDataDictRelationList = params => {
    return axios.get(`${base}/aidrwebapi/expertDataDictRelation/getExpertDataDictRelationList`, { params: params });
};
//获取验收专家列表
export const getLocalExpertList = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getLocalExpertList`, { params: params });
};
//验收管理接口 分配初验驻场人员
export const assignLocalExpertUsers = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/assignLocalExpertUsers`, params);
};
//追加初验驻场人员
export const appendLocalExpertUsers = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/appendLocalExpertUsers`, params);
};
//验收 获取当前意见列表
export const getCurrentOpinionList = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getCurrentOpinionList`, { params: params });
};
//验收 添加意见
export const addOpinion = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/addOpinion`, params);
};
//验收 获取项目阶段明细
export const getAcceptanceByProjectId = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getStageListByProjectId`, { params: params });
};
//验收 获取指定阶段意见列表（清单）
export const getChecklistOpinionListByWorkFlowId = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getChecklistOpinionListByWorkFlowId`, { params: params });
};
//验收 获取指定阶段评定报告
export const getReportInfoByWorkFlowId = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getReportInfoByWorkFlowId`, { params: params });
};
//验收 获取指定阶段意见列表
export const getOpinionListByWorkFlowIdAcceptance = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getOpinionListByWorkFlowId`, { params: params });
};
//验收 确认生成清单(阅卷）
export const generateCheckList = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/generateCheckList`, params);
};
//验收 确认生成清单（交卷）
export const submitConfirmOpinion = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/submitConfirmOpinion`, params);
};
//验收 获取指定阶段评定报告
export const getReportInfoByWorkFlowIdAcceptance = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getReportInfoByWorkFlowId`, { params: params });
};
//验收 获取当前评定报告
export const getCurrentReportInfo = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getCurrentReportInfo`, { params: params });
};
//验收 修改报告意见
export const updateReportOptions = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/updateReportOptions`, params);
};
//验收 修改意见
export const updateOptions = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/updateOptions`, params);
};
//验收 生成评定报告
export const generateReport = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/generateReport`, params);
};
//验收 添加复验
export const addReviewTask = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/addReviewTask`, params);
};
//验收 checklist编辑
export const updateChecklistOptions = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/updateChecklistOptions`, params);
};
//验收 编辑工程项目
export const updateEngineerProject = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/updateProject`, params);
};



export const registerReviewProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/registerReviewProject`, params);
};

// 复核项目多人分配
export const registerMultipleReviewProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/registerMultipleReviewProject`, params);
};

// 专家审核登记
export const registerExpertProject = params => {
    return axios.post(`${base}/aidrwebapi/drProject/registerExpertProject`, params);
};

// 新增
export const addExpertDataDictRelation = params => {
    return axios.post(`${base}/aidrwebapi/expertDataDictRelation/addExpertDataDictRelation`, params);
};
// 编辑
export const updateExpertDataDictRelation = params => {
    return axios.post(`${base}/aidrwebapi/expertDataDictRelation/updateExpertDataDictRelation`, params);
};
// 删除
export const deleteExpertDataDictRelation = params => {
    return axios.delete(`${base}/aidrwebapi/expertDataDictRelation/deleteExpertDataDictRelation`, { params: params });
};

// 合同管理

// 合同列表
export const getDrContractList = params => {
    return axios.get(`${base}/aidrwebapi/drContract/getDrContractList`, { params: params });
};

// 获取当前登陆人的有效合同
export const getCurrentDrContract = params => {
    return axios.get(`${base}/aidrwebapi/drContract/getCurrentDrContract`, { params: params });
};
//获取当前登录人的有效项目
export const getCurrentDrContractList = params => {
    return axios.get(`${base}/aidrwebapi/drContract/getCurrentDrContractList`, { params: params });
};
// 验收意见 获取当前清单意见列表
export const getCurrentChecklistOpinionList = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getCurrentChecklistOpinionList`, { params: params });
};
//验收 删除现场评定清单意见
export const deleteOpinionAcceptance = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/deleteOpinion`, { params: params });
};

// 合同联系人列表
export const getContractContactUser = params => {
    return axios.get(`${base}/aidrwebapi/drContract/getContractContactUser`, { params: params });
};

// 合同联系人列表
export const getContractApplyUser = params => {
    return axios.get(`${base}/aidrwebapi/drContract/getContractApplyUser`, { params: params });
};

// 合同联系人列表
export const getContractCollaboratorUser = params => {
    return axios.get(`${base}/aidrwebapi/drContract/getContractCollaboratorUser`, { params: params });
};

// 受领任务

// 获取受领任务列表
export const getTaskApplyList = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getApplyList`, { params: params });
};

// 处理任务接口
export const confirmTask = params => {
    return axios.post(`${base}/aidrwebapi/drTask/confirmTask`, params);
};

//验收项目 新建项目
export const addProjectAcceptance = params => {
    return axios.post(`${base}/aidrwebapi/aeProject/addProject`, params);
};

// 待指派任务
// 获取受领任务列表
export const getTaskAssignList = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getAssignList`, { params: params });
};

// 分配任务接口
export const assignTask = params => {
    return axios.post(`${base}/aidrwebapi/drTask/assignTask`, params);
};

// 根据任务ID获取任务详情列表
export const getTaskDetailList = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getTaskDetailByIds`, { params: params });
};

//修改技术部专家
export const changeAssignedUserIdByTechExpert = params => {
    return axios.post(`${base}/aidrwebapi/drTask/changeAssignedUserIdByTechExpert`, params);
};

// 结算考核
// 经营报表

// 获取经营报表分页列表
export const getProjectListObject = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getProjectConclusionReportList`, { params: params });
};

// 获取经营报表汇总
export const getProjectConclusionSummary = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getProjectConclusionSummary`, { params: params });
};
//验收管理
//获取项目分页列表
export const getAcceptanceProjectList = params => {
    return axios.get(`${base}/aidrwebapi/aeProject/getProjectList`, { params: params });
};
//获取验收问题库分页列表
export const getAcceptanceQuestionPageList = params => {
    return axios.get(`${base}/aidrwebapi/drQuestion/getAcceptanceQuestionPageList`, { params: params });
};

// 业绩考核
// 业绩考核列表
export const getPerformanceReportList = params => {
    return axios.get(`${base}/aidrwebapi/drPerformanceAssessmentSt/getPerformanceReportList`, { params: params });
};
//绩效考核列表
export const getUserStatisticList = params => {
    return axios.get(`${base}/aidrwebapi/drPerformanceAssessmentSt/getUserStatisticList`, { params: params });
};
//绩效明细详情
export const getStatisticsProjectDetailReportData = params => {
    return axios.get(`${base}/aidrwebapi/drPerformanceAssessmentSt/getStatisticsProjectDetailReportData`, { params: params });
};

// 获取审核员项目明细列表
export const getUserProjectDetailList = params => {
    return axios.get(`${base}/aidrwebapi/drPerformanceAssessmentSt/getUserProjectDetailList`, { params: params });
};
//获取用户的项目行为日志
export const getUserProjectBehaviorList = params => {
    return axios.get(`${base}/aidrwebapi/UserBehavior/getUserProjectBehaviorList`, { params: params });
};
//通过流程节点获取用户行为日志
export const getUserBehaviorLogByWorkFlowNodeId = params => {
    return axios.get(`${base}/aidrwebapi/UserBehavior/getUserBehaviorLogByWorkFlowNodeId`, { params: params });
};

// 获取审核员拒领项目列表
export const getUserRefuseProjectList = params => {
    return axios.get(`${base}/aidrwebapi/drPerformanceAssessmentSt/getUserRefuseProjectList`, { params: params });
};

// 应收款周期报表分页列表
export const getFinancialReceivableListPage = params => {
    return axios.get(`${base}/aidrwebapi/financialReceivableCycle/getFinancialReceivableListPage`, { params: params });
};

//获取营收账单分页列表
export const getBillList = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getBillList`, { params: params });
};

// 应收款周期报表分页列表  催收通知  警示通知
export const getNotificationListPageByContractIdAndCycleId = params => {
    return axios.get(`${base}/aidrwebapi/financialReceivableCycle/getNotificationListPageByContractIdAndCycleId`, { params: params });
};
// 应收款周期报表分页列表  获取待核销的所有银行流水凭证
export const getPaymentsByNoMatchList = params => {
    return axios.get(`${base}/aidrwebapi/financialReceivableCycle/getPaymentsByNoMatchList`, { params: params });
};
// 应收款周期报表分页列表  流水匹配（匹配实收到对应周期报表）
export const addRevenuePaymentDetail = params => {
    return axios.post(`${base}/aidrwebapi/financialReceivableCycle/addRevenuePaymentDetail`, params);
};
// 应收款周期报表分页列表  流水匹配（根据应收款报表ID、获取该周期报表下的所有匹配明细）
export const getPaymentDetailListPageByCycleId = params => {
    return axios.get(`${base}/aidrwebapi/financialReceivableCycle/getPaymentDetailListPageByCycleId`, { params: params });
};
// 应收款周期报表分页列表  获取应收款周期报表对应的项目明细接口
export const getProjectByCycleId = params => {
    return axios.get(`${base}/aidrwebapi/financialReceivableCycle/getProjectByCycleId`, { params: params });
};
//应收款报表分页列表 项目
export const getProjectListByIds = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getProjectListByIds`, { params: params });
};
//应收款报表明细列表
export const getReportList = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getReportList`, { params: params });
};
//应收款报表明细 合同ID
export const getCompanyContractList = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getCompanyContractList`, { params: params });
};
//汇总月
export const getBillDetailByMonth = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getBillDetailByMonth`, { params: params });
};


// 应收款周期报表分页列表  应收款报表按周期采集数据接口
export const addFinancialReceivableByContractID = params => {
    return axios.get(`${base}/aidrwebapi/financialReceivableCycle/addFinancialReceivableByContractID`, { params: params });
};
// 获取应收款周期报表下的合同列表
export const getProjectByContractId = params => {
    return axios.get(`${base}/aidrwebapi/financialReceivableCycle/getProjectByContractId`, { params: params });
};

//营收管理
//银行收款凭证列表
export const getIncomePaymentList = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getIncomePaymentList`, { params: params });
};
//获取发票列表
export const getInvoiceList = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getInvoiceList`, { params: params });
};

export const getAllProjectList = params => {
    return axios.get(`${base}/aidrwebapi/drProject/getAllProjectList`, { params: params });
};

export const getInvoiceDetailList = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getInvoiceDetailList`, { params: params });
};
export const getUnMatchInvoiceList = params => {
    return axios.get(`${base}/aidrwebapi/financialRevenue/getUnMatchInvoiceList`, { params: params });
};
//获取项目列表
export const getProjectMatchList = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getProjectConclusionReportList`, { params: params });
};
export const getProjectConclusionReportList = params => {
    return axios.get(`${base}/aidrwebapi/drProjectSt/getProjectConclusionReportList`, { params: params });
};
//匹配发票
export const matchRevenuePaymentByInvoice = params => {
    return axios.post(`${base}/aidrwebapi/financialRevenue/matchRevenuePaymentByInvoice`,  params );
};
//匹配项目
export const matchRevenuePaymentByProject = params => {
    return axios.post(`${base}/aidrwebapi/financialRevenue/matchRevenuePaymentByProject`, params);
};
//申请发票
export const addFinancialInvoice = params => {
    return axios.post(`${base}/aidrwebapi/financialRevenue/addFinancialInvoice`, params);
};
//提交申请
export const submitFinancialApplyInvoice = params => {
    return axios.post(`${base}/aidrwebapi/financialRevenue/submitFinancialApplyInvoice`, params);
};

//保存编辑
export const editFinancialInvoice = params => {
    return axios.post(`${base}/aidrwebapi/financialRevenue/editFinancialInvoice`, params);
};
//审核
export const reviewFinancialApplyInvoice = params => {
    return axios.post(`${base}/aidrwebapi/financialRevenue/reviewFinancialApplyInvoice`, params);
};
// 意见列表和汇总

// 获取我的待提交项目列表
export const getUncommitProjectList = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getUncommitProjectList`, { params: params });
};

// 获取我的意见列表
export const getOpinionAllList = params => {
    return axios.get(`${base}/aidrwebapi/drOpinion/getOpinionAllList`, { params: params });
};

// 删除我的意见
export const deleteOpinion = params => {
    return axios.delete(`${base}/aidrwebapi/drOpinion/deleteOpinion`, { params: params });
};

// 提交我的意见
export const submitDrawingReviewReport = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/submitDrawingReviewReport`, { params: params });
};

// // 根据项目id获取需要汇总的人员列表
// export const getGatherUserListByProjectId = params => {
//     return axios.get(`${base}/aidrwebapi/drTask/getGatherUserListByProjectId`, { params: params });
// };

// 获取我的待汇总项目列表
export const getUngatherProjectList = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getUngatherProjectList`, { params: params });
};

// 获取用户项目任务详情
export const getUnFinishTaskListByUser = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getUnFinishTaskListByUser`, { params: params });
};

// 根据项目id获取需要汇总的人员列表
export const getPeopleCount = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/getPeopleCount`, { params: params });
};

// 获取自动汇总/指定人员的意见列表
export const getDrOpinionSummaryUnconfirmed = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/getDrOpinionSummaryUnconfirmed`, { params: params });
};

// 获取自动汇总/指定人员的意见列表
export const getDrOpinionSummaryUnconfirmedForUser = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/getDrOpinionSummaryUnconfirmedForUser`, { params: params });
};

// 获取已确认的意见列表
export const getDrOpinionSummaryConfirmed = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/getDrOpinionSummaryConfirmed`, { params: params });
};

// 编辑已确认审查意见
export const updateConfirmedOpinion = params => {
    return axios.post(`${base}/aidrwebapi/drOpinionSummary/updateConfirmedOpinion`, params);
};

// 获取自动汇总的意见列表
export const getQuesTypeSummaryUnconfirmed = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/getQuesTypeSummaryUnconfirmed`, { params: params });
};

// 生成报告
export const generatePreliminaryReviewReport = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/generatePreliminaryReviewReport`, { params: params });
};

// 添加已确认审查意见
export const addOpinionConfirmed = params => {
    return axios.get(`${base}/aidrwebapi/drOpinionSummary/addOpinionConfirmed`, { params: params });
};

// 删除已确认的意见
export const deleteConfirmedOpinion = params => {
    return axios.delete(`${base}/aidrwebapi/drOpinionSummary/deleteConfirmedOpinion`, { params: params });
};

export const getNotificationWebsiteListPage = params => {
    return axios.get(`${base}/aidrwebapi/notificationWebsite/getNotificationWebsiteListPage`, { params: params });
};

export const getNotificationWebSiteUnReadCount = params => {
    return axios.get(`${base}/aidrwebapi/notificationWebsite/getNotificationWebsiteUnReadCount`, { params: params });
};


export const getGenWorkBriefList = params => {
    return axios.get(`${base}/aidrwebapi/genWorkBrief/getGenWorkBriefList`, { params: params });
};

// 修改站内通知记录已读状态
export const updateNotificationWebsiteIsRead = params => {
    return axios.post(`${base}/aidrwebapi/notificationWebsite/updateNotificationWebsiteIsRead`, params);
}

// 根据年、月、状态获取项目名称
export const getProjectNameList = params => {
    return axios.get(`${base}/aidrwebapi/zjjSt/getProjectList`, {params});
}

// 获取项目统计信息接口
export const getProjectStInfo = project_id => {
    return axios.get(`${base}/aidrwebapi/zjjSt/getProjectStInfo`, {params: {project_id: project_id}});
}

// 获取工作报告接口
export const getWorkReportList = (ddIdTimeType, pageIndex, pageSize) => {
    return axios.get(`${base}/aidrwebapi/zjjSt/getWorkReportList`, {params: {ddIdTimeType, pageIndex, pageSize}});
}

// 获取工作报告项目详情接口
export const getProjectDetailByWorkReport = (report_id, type) => {
    return axios.get(`${base}/aidrwebapi/zjjSt/getProjectDetailByWorkReport`, {params: {report_id, type}});
}

// 获取问题数接口
export const getQuestionDetailByWorkReport = (report_id, type) => {
    return axios.get(`${base}/aidrwebapi/zjjSt/getQuestionDetailByWorkReport`, {params: {report_id, type}});
}



// 新增客户端版本
export const addAppVersion = (req) => {
    return axios.post(`${base}/aidrwebapi/appVersion/addAppVersion`, req, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

// 删除客户端版本
export const deleteAppVersion = (params) => {
    return axios.delete(`${base}/aidrwebapi/appVersion/deleteAppVersion`, { params: params });
}

// 获取客户端版本分页列表
export const getAppVersionList = (params) => {
    return axios.get(`${base}/aidrwebapi/appVersion/getAppVersionList`, { params: params });
}

// 获取客户端版本分页列表
export const updateAppVersion = (params) => {
    return axios.post(`${base}/aidrwebapi/appVersion/updateAppVersion`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

//受领任务（列表）
export const getAssignList = params => {
    return axios.get(`${base}/aidrwebapi/drTask/getAssignList`, { params: params });
};
//受领任务开业检查
export const acceptKYJCTask = params => {
    return axios.get(`${base}/aidrwebapi/drTask/acceptKYJCTask`, { params: params });
};